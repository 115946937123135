import * as Sentry from "@sentry/browser"
import { BrowserTracing } from "@sentry/tracing"

import { greenfigEnrollment } from './components/enrollment'
import { greenfigSyllabus } from './components/syllabus'

import './css/styles.css'
import './scss/theme.scss'

Sentry.init({
	dsn: "https://538ea575e970448cb6d7bb8720e01ea2@o559512.ingest.sentry.io/4504023395926016",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.2,
  });

window.greenfigEnrollment = greenfigEnrollment
window.greenfigSyllabus = greenfigSyllabus