import * as Sentry from "@sentry/browser"
import Cookies from "js-cookie"

declare global {
    interface Window { 
		location: Location
		dataLayer: any
	}
}

console.info('parsing greenfigSyllabus')

const PRODUCTION_ENV = 'production'
const STAGING_ENV = 'staging'
const DEVELOPMENT_ENV = 'development'

const PRODUCTION_HOST = 'www.greenfig.com'
const PRE_PRODUCTION_HOST = 'greenfig.webflow.io'
const STAGING_HOST = 'greenfig-stage.webflow.io'
// const DEVELOPMENT_HOST = 'dev.greenfig.com'

// 'CLAS' backend API - Because someone, somewhere sucks at naming software
const CLAS_PRODUCTION_API_URL = 'https://clas.greenfig.com/api/v1/syllabus_requests'
const CLAS_STAGING_API_URL = 'https://staging-clas.greenfig.com/api/v1/syllabus_requests'
const CLAS_DEVELOPMENT_API_URL = 'https://staging-clas.greenfig.com/api/v1/syllabus_requests'

export function greenfigSyllabus(config: SyllabusConfiguration) {
	try {
		const page_url = new URL(String(window.location))

		console.info('Syllabus initialized - build 2', page_url.toString(), config)

		// let paymentUrl : URL
		let syllabusApiUrl: URL
		let submitted = false,
			submitSuccess = false

		const frm_data: any = {},
			final_data: BackendSyllabusRequest | any = {}

		let item: any = null,
			form: HTMLElement | null = null

		if (window.hbspt
			&& config
			&& config.portalId
			&& config.formId) {
			window.hbspt.forms.create({
				portalId: config.portalId,
				formId: config.formId
			})
		}

		let env: string,
			pre_production = false

		if (isDevHost()) {
			env = DEVELOPMENT_ENV
			syllabusApiUrl = new URL(CLAS_DEVELOPMENT_API_URL)
		}
		else if (isStagingHost()) {
			env = STAGING_ENV
			syllabusApiUrl = new URL(CLAS_STAGING_API_URL)
		}
		else if (isPreProductionHost()) {
			console.warn('IN PRE-PRODUCTION')
			env = PRODUCTION_ENV
			pre_production = true
			syllabusApiUrl = new URL(CLAS_PRODUCTION_API_URL)
		}
		else // default to production
		{
			env = PRODUCTION_ENV
			syllabusApiUrl = new URL(CLAS_PRODUCTION_API_URL)
		}

		console.warn('env', env, syllabusApiUrl.toString())

		const urlParams = new URLSearchParams(window.location.search)
		let promo_key :number|string|null = urlParams.get('key')

		if(promo_key)
		{
			console.info('promo_code', promo_key)
			localStorage.setItem('promo_code', promo_key)
		}

		let submit = async function () {
			if (!submitted) {
				console.warn('!submitted')
				return
			}

			if (!submitSuccess) {
				console.warn('!submitSuccess')
				return
			}

			window.localStorage.setItem('syllabus.url', page_url.toString())

			// --

			if (frm_data['first_name'])
				final_data['first_name'] = frm_data['first_name']
			else if (frm_data['firstname'])
				final_data['first_name'] = frm_data['firstname']

			if (frm_data['email'])
				final_data['email'] = frm_data['email']

			if (frm_data['phone'])
				final_data['phone'] = frm_data['phone']
			else if (frm_data['phone_number'])
				final_data['phone'] = frm_data['phone_number']

			final_data.page_url = page_url.toString()

			console.warn('Final syllabus request data', final_data)

			const response = await fetch(syllabusApiUrl.toString(),
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(final_data)
				})

			console.info('response', response)

			const data : {
				data: {
					hubspot: {
						contact_id: string
					}
				}
			} = await response.json()

			console.info('response data', data)

			if(data?.data?.hubspot?.contact_id)
			{
				console.info('setting Cookie', data.data.hubspot.contact_id)

				Cookies.set('user_id', data.data.hubspot.contact_id, {'SameSite': 'Strict'})

				if(window.dataLayer)
					window.dataLayer.push({ event: "user_id_set", user_id: data.data.hubspot.contact_id })
			}
			else if(window.dataLayer)
				window.dataLayer.push({ event: "user_id_not_set" })

			if (config.redirectUrl)
				window.location.assign(config.redirectUrl)
		}

		window.addEventListener('message', (event) => {
			try {
				if (event.data && event.data.id !== config.formId)
					return;

				console.info('Form event for', event.data.id)

				if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
					console.warn('onFormReady', event.data)

					form = document.getElementById('hbspt-form-' + event.data.id)

					console.warn('form', form)
				}
				else if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
					console.warn('onFormSubmit', event.data.data)

					if (Array.isArray(event.data.data)) {
						for (let item of event.data.data) {
							console.info('Processing ', item.name, ':', item.value)

							frm_data[item.name] = item.value
						}
					}
					else if (event.data.data) {
						for (const [name, value] of Object.entries(event.data.data)) {
							console.info('Processing ', name, ':', value)

							frm_data[name] = value
						}
					}

					submitted = true
					submit()
				}
				else if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
					console.warn('onFormSubmitted')

					submitSuccess = true
					submit()
				}
			}
			catch (e) {
				console.error(e)
				Sentry.captureException(e)
			}
		})
	}
	catch (e) {
		console.error(e)
		Sentry.captureException(e)
	}
}

// ---

function isDevHost() {
	const parts = window.location.host.split('.')
	return parts.indexOf('dev') !== -1
}

function isStagingHost() {
	return window.location.host.indexOf('staging') !== -1
		|| window.location.host.indexOf('stage') !== -1
}

function isPreProductionHost() {
	return window.location.host == PRE_PRODUCTION_HOST
}


// ---

type SyllabusConfiguration =
	{
		portalId: string,
		formId: string,
		redirectUrl: string
	}

type BackendSyllabusRequest =
	{
		email: string,
		first_name: string,
		phone: string,
		page_url: string
	}

//

declare global {
	interface Window {
		greenfigSyllabus: (config: any) => void,
		hbspt: any
	}
}