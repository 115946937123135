import * as Sentry from "@sentry/browser"
import Cookies from "js-cookie"

console.info('parsing greenfigEnrollment')

const PRODUCTION_ENV = 'production'
const STAGING_ENV = 'staging'
const DEVELOPMENT_ENV = 'development'

const PRODUCTION_HOST = 'www.ziplines.com'
const PRE_PRODUCTION_HOST = 'greenfig.webflow.io'
const STAGING_HOST = 'greenfig-stage.webflow.io' 
// const DEVELOPMENT_HOST = 'dev.greenfig.com'

// 'CLAS' backend API - Because someone, somewhere sucks at naming software
const CLAS_PRODUCTION_API_URL = 'https://clas.ziplines.com/api/v1/submissions'
const CLAS_STAGING_API_URL = 'https://staging-clas.ziplines.com/api/v1/submissions'
const CLAS_DEVELOPMENT_API_URL = 'https://staging-clas.ziplines.com/api/v1/submissions'

export function greenfigEnrollment(config : EnrollmentConfiguration)
{
	try
	{
		const localUrl = new URL(String(window.location))
		
		console.info('Enrollment initialized - build 26', localUrl.toString(), config)
		
		// let paymentUrl : URL
		let enrollmentApiUrl : URL
		let submitted = false, 
			submitSuccess = false
		
		const frm_data : any = [],
			final_data: BackendEnrollmentRequest | any  = {},
			params : any = [],
			whitelist : any = config.whitelist || [],
			program = config.program,
			partner_channel = config.partnerChannel,
			partner_form_token = config.partnerFormToken,
			criteria : any = config.criteria || {},
			defaultPlan : any = config.defaultPlan || null
		
		let item : any = null,
			name = '',
			value = '',
			plan : any = config.plan || null,
			form : HTMLElement|null = null
		
		if(window.hbspt
			&& config.form
			&& config.form.region
			&& config.form.portalId
			&& config.form.formId)
		{
			window.hbspt.forms.create(config.form)
		}

		if(config.channel)
			console.info('got channel from configuration', config.channel)

		let env : string,
			pre_production = false

		if(isDevHost())
		{
			env = DEVELOPMENT_ENV
			// paymentUrl = new URL(`https://${DEVELOPMENT_HOST}/${payment_path}`)
			enrollmentApiUrl = new URL(CLAS_DEVELOPMENT_API_URL)
		}
		else if(isStagingHost())
		{
			env = STAGING_ENV
			// paymentUrl = new URL(`https://${STAGING_HOST}/${payment_path}`)
			enrollmentApiUrl = new URL(CLAS_STAGING_API_URL)
		}
		else if(isPreProductionHost())
		{
			console.warn('IN PRE-PRODUCTION')
			env = PRODUCTION_ENV
			pre_production = true
			// paymentUrl = new URL(`https://${PRODUCTION_HOST}/${payment_path}`)
			enrollmentApiUrl = new URL(CLAS_PRODUCTION_API_URL)
		}
		else // default to production
		{
			env = PRODUCTION_ENV
			// paymentUrl = new URL(`https://${PRODUCTION_HOST}/${payment_path}`)
			enrollmentApiUrl = new URL(CLAS_PRODUCTION_API_URL)
		}

		console.warn('env', env, enrollmentApiUrl.toString())

		const urlParams = new URLSearchParams(window.location.search)
		let promo_key :number|string|null = urlParams.get('key')

		if(!promo_key)
			promo_key = localStorage.getItem('promo_code')

		if(promo_key)
			console.info('promo_code', promo_key)
			
		let submitEnrollment = async function()
		{
			if(!submitted)
			{
				console.warn('!submitted')
				return
			}

			if(!submitSuccess)
			{
				console.warn('!submitSuccess')
				return
			}

			window.localStorage.setItem('enrollment.url', localUrl.toString())

			if(config.type === 'questionnaire')
			{
				for(item of frm_data)
				{
					name = item['name']
					value = item['value']
					final_data[name] = value
					
					//console.warn('processing param', name, value)
					
					if(!plan)
					{
						if(name in criteria)
						{
							//console.warn('name', name, 'in criteria')
							if(value in criteria[name])
							{
								//console.warn('value', value, 'in', name, 'criteria')
								plan = criteria[name][value]
							}
						}
					}
					
					if(whitelist.indexOf(name) !== -1)
						params.push({name: name, value: value})
				}
			}
			
			if(!plan)
				plan = defaultPlan
			
			final_data.plan = plan

			if(final_data['firstname'] || final_data['lastname'])
				params.push({name: 'learner_name', value: `${final_data['firstname']} ${final_data['lastname']}`})

			if(final_data['email'])
				params.push({name: 'email', value: final_data['email']})
			
			params.unshift({name: 'plan', value: plan})
			
			if(!final_data['phone_number'])
				final_data['phone_number'] = final_data['phone']
					|| final_data['phoneNumber']
			
			final_data['program'] = program
			final_data['partner_channel'] = partner_channel
			final_data['partner_form_token'] = partner_form_token

			if(promo_key)
				final_data['promo_code'] = promo_key
			
			console.warn('Final enrollment request data', final_data)
			
			final_data.source_url = String(window.location)
			final_data.user_agent = window.navigator.userAgent

			const formBody = []
			for(const property in final_data)
			{
				formBody.push(
					encodeURIComponent(property) + "="
					+ encodeURIComponent(final_data[property]))
			}

			const response = await fetch(enrollmentApiUrl.toString(),
				{
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				},
				body: formBody.join("&")
			})
			
			const result : BackendEnrollmentResponse = await response.json()

			console.warn('Enrollment response', result)

			if(result?.hubspot_contact_id)
			{
				console.info('setting Cookie', result.hubspot_contact_id)

				Cookies.set('user_id', result.hubspot_contact_id, {'SameSite': 'Strict'})

				if(window.dataLayer)
					window.dataLayer.push({ event: "user_id_set", user_id: result.hubspot_contact_id })
			}
			else if(window.dataLayer)
				window.dataLayer.push({ event: "user_id_not_set" })

			if(result.status === 'saved')
			{
				// params.unshift({name: 'enrollment_id', value: result.gf_enrollment_id})
				
				// if(result.cohort_timeframe)
				// 	params.unshift({name: 'cohort_timeframe', value: result.cohort_timeframe})

				// const channel = result.channel || config.channel
				// if(channel)
				// 	params.unshift({name: 'channel', value: channel})
				
				// params.forEach(function (el : { name : string, value : string })
				// {
				// 	paymentUrl.searchParams.set(el.name, el.value)
				// })

				// console.warn('Final payment url', paymentUrl)
				// window.location.href = paymentUrl.toString()

				console.warn('Enrollment API\'s payment url', result.payment_url)

				const paymentUrl = new URL(result.payment_url)

				if(pre_production)
					paymentUrl.host = PRE_PRODUCTION_HOST

				const channel = result.channel || config.channel
				if(channel)
					paymentUrl.searchParams.set('channel', String(channel))

				if(promo_key)
					paymentUrl.searchParams.set('key', String(promo_key))

				console.warn('Final payment url', paymentUrl.toString())

				window.location.href = paymentUrl.toString()
			}
			else
			{
				console.warn('Enrollment failure - Status:', result.status)
				Sentry.captureMessage(`Enrollment failure - Status: ${result.status}`)
				
				const iframes = document.getElementsByClassName("hs-form-iframe")
				
				const getMessage = function(message : string|null = null)
				{
					const email = `<a href="mailto:admissions@ziplines.com">admissions@ziplines.com</a>`
					
					switch(message)
					{
						case 'is closed for Enrollment':
							message = `<h1>Sorry... It looks like the add deadline for the cohort you are attempting to join has passed.</h1>\n`
							break
						default:
							message = `<h1>It looks like we had an issue with your Enrollment.</h1>\n`
					}
					
					return `<div style="text-align: center;">\n${message}<p>Please contact one of our admissions team members at ${email} to enroll in this or a future cohort.</p>\n</div>`
				}
				
				const message = getMessage(result.errors && result.errors.cohort && result.errors.cohort[0])
				
				if(iframes && iframes[0])
					iframes[0].outerHTML = message;
				else
				{
					console.warn('No form found - dumping message to document')
					document.write(message)
				}
			}
		}
		
		window.addEventListener('message', (event) =>
		{
			try
			{
				if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady')
				{
					console.warn('onFormReady', event.data)
					
					form = document.getElementById('hbspt-form-'+event.data.id)
					
					console.warn('form', form)
				}
				else if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit')
				{
					console.warn('onFormSubmit', event.data.data)

					if(Array.isArray(event.data.data))
					{
						for(let item of event.data.data)
						{
							console.info('Processing ', item.name, ':', item.value)

							frm_data.push(item)
							final_data[item.name] = item.value
						}
					}
					else if(event.data.data)
					{
						for(const [name, value] of Object.entries(event.data.data))
						{
							console.info('Processing ', name, ':', value)

							frm_data.push(item)
							final_data[name] = value
						}
					}
					
					submitted = true
					submitEnrollment()
				}
				else if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted')
				{
					console.warn('onFormSubmitted')

					submitSuccess = true
					submitEnrollment()
				}
			}
			catch(e)
			{
				console.error(e)
				Sentry.captureException(e)
			}
		})
	}
	catch(e)
	{
		console.error(e)
		Sentry.captureException(e)
	}
}

// ---

function isDevHost()
{
	const parts = window.location.host.split('.')
	return parts.indexOf('dev') !== -1
}

function isStagingHost()
{
	return window.location.host.indexOf('staging') !== -1 
		|| window.location.host.indexOf('stage') !== -1
}

function isPreProductionHost()
{
	return window.location.host == PRE_PRODUCTION_HOST
}


// ---

const EnrollmentTypeMap = Object.freeze({
	'default': "",
	questionnaire: "questionnaire"
})

type EnrollmentType = keyof typeof EnrollmentTypeMap

interface EnrollmentConfiguration
{
	program: string
	partnerChannel: string
	partnerFormToken: string

	type: EnrollmentType
	channel?: string,
	
	plan?: string
	defaultPlan?: string
	
	whitelist?: Array<string>
	criteria?: {
		'field-name' : {
			'field-value' : 'plan-id',
			'field-value2' : 'plan-id2'
		}
	}

	form: {
		region: string
		portalId: string
		formId: string
	}
}

interface BackendEnrollmentRequest
{
	'email' : string
	'phone_number' : string
	'program' : string
	'partner_channel' : string
	'partner_form_token' : string
	'plan': string
}

interface BackendEnrollmentResponse
{
    enrollment_id: string // "enrl_sDeWqI8ja0JNjljqYK8idA"
	cohort_timeframe?: string
	channel?: string
    status: string //"saved",
    errors: any // {}
	payment_url: string
	hubspot_contact_id: string
}

//

declare global
{
	interface Window {
		greenfigEnrollment: (config:any) => void,
		hbspt: any
	}
}